<template>
  <table :class="computedClasses">
    <thead v-if="$slots.thead">
      <slot name="thead" />
    </thead>
    <tbody>
      <slot />
    </tbody>
  </table>
</template>

<script setup lang="ts">
import type { TableSize } from '~/types/style-guide'

const props = withDefaults(defineProps<{
  size?: TableSize
}>(), {
  size: 'md',
})

const computedClasses = computed(() => {
  return [
    'ztable',
    `ztable-${props.size}`,
  ]
})
</script>
